<template>
	<div
		ref="body"
		class=""
	>
		<div
			class="pa-10-pc bg-base-pc"
		>
			<div class="container-pc justify-space-between-pc items-center">
				<div class="pa-10-20-m bg-base-m">
					<ul>
						<li
							v-for="(category, c_index) in category_list"
							:key="'category_' + category.category_code"

							class="inline-block mr-10"
						>
							<button
								class="font-weight-bold size-px-13"
								:class="category.category_code == category_now.category_code ? 'color-primary' : ''"
								@click="toCategory(category)"
							>{{ category.category_name }}</button>
							<span
								v-if="category_list.length > 0 && c_index < category_list.length - 1"
							> |</span>
						</li>
					</ul>
				</div>
				<div class="container-m mt-10-m justify-space-between gap-10-pc hide-m">
					<Search
						v-if="program.search"
						:program="program"
						:search="search"
						:option="item_search_option"

						:is_top_line="true"

						@click="getSearch"
					>
						<template
							v-slot:first
						>
							<select
								v-model="search.sort"
								class="pa-5 size-px-12 mr-10-pc"
								@change="getSearch"
							>
								<option
									v-for="sort in codes.P002.items"
									:key="'sort_' + sort.sub_code"
									:value="sort.code_value ? sort.code_value : ''"
								>{{ sort.code_name }}</option>
							</select>
						</template>
					</Search>

				</div>
			</div>

			<div
				class="hide-pc mb-20 mt-10"
			>
				<div class=" justify-space-between ">
					<select
						v-model="search.sort"
						class="pa-5 box size-px-12 mr-10-pc"
						@change="getSearch"
					>
						<option
							v-for="sort in codes.P002.items"
							:key="'sort_' + sort.sub_code"
							:value="sort.code_value ? sort.code_value : ''"
						>{{ sort.code_name }}</option>
					</select>

					<div class="justify-space-between flex-1">
						<input
							v-model="search.search_value"
							class="pa-5-10 input-box-inline mr-10-pc flex-1 width-10"
							placeholder="검색어를 입력하세요"
							@keyup.enter="getSearch"
						/>

						<button
							class="btn btn-fill btn-s"
							@click="getSearch"
						>검색</button>
					</div>
				</div>
			</div>
		</div>

		<div
			class=" bg-white"
		>
			<div class="ptb-50-pc">
				<div
					class="container mt-20"
				>

					<div
						v-if="items.length > 0"
						class=" "
					>
						<ul
							class=" main-pdt flex-row-wrap justify-start gap-20"
							:class="list_type"
						>
							<li
								v-for="pdt in item_list"
								:key="pdt.pdt_uid"
								class="list-box-pdt text-center box box-shadow pb-30-pc pb-10-m radius-20 cursor-pointer"

								@click="toDetail(pdt)"
							>

								<div class="prl-301"><img :src="pdt.pdt_img1" @error="$bus.$emit('onErrorImage', $event)" class="object-cover"/></div>
								<hr class="mt-201 mb-10 under-line">
								<div class="prl-30-pc prl-10-m size-px-20-pc size-px-12-m font-weight-600 color-dark text-left line-height-150 flex-column justify-space-between">
									<div class="pdt-box">
										<div v-if="false">[{{ pdt.pdt_category}}]</div>
										<div class="escape">{{ pdt.pdt_name}}</div>
										<div>{{ pdt.pdt_summary}}</div>
									</div>
									<div class="justify-space-between">
										<div></div>
										<div class="size-px-14-pc size-px-9-m font-weight-400 mt-10"><span class="color-error size-px-20-pc size-px-14-m font-weight-bold">{{ pdt.pdt_price | makeComma}}</span>원</div>
									</div>
								</div>

							</li>
						</ul>

						<Pagination
							:program="program"
							:align="'center'"
							:options="search"

							@click="getData"

							class="pa-10 mt-50"
						></Pagination>

					</div>
					<Empty
						v-else
					></Empty>
				</div>
			</div>
		</div>

		<More
			v-if="$refs.body"
			:body="$refs.body"
		></More>
	</div>
</template>

<script>

import Pagination from "@/components/Pagination";
import Search from "@/view/Layout/Search";
import Empty from "@/view/Layout/Empty";
import More from "../Layout/More";

export default{
	props: ['user', 'codes', 'category', 'member_info', 'shop_info']
	,components: {More, Empty, Search, Pagination }
	,data: function(){
		return {
			program: {
				name: "상품 목록 "
				, code: 'product_list'
				, title: true
				, search: true
				, bottom: true
				, top_line: true
				, is_container: true
			}
			,search: {
				search_type: 'pdt_name'
				, search_value: ''
				, pdt_category: this.$route.params.category
				, sort: 'new'
				, pdt_div: this.$route.params.pdt_div
				, page: 1
				, list_cnt: 12
				, type: 'list'
			}
			, item_search_option: {
				is_keyword: false
				, is_cnt: false
				, is_list_cnt: true
				, search_type: [{ column: 'pdt_name', name: '상품명'}]
			}
			,summary: {
				total: 0
			}
			,items: [

			]
			,item: {

			}
			,category_now: {
				category_code: ''
			}
			,list_type: localStorage.getItem('list_type') ? localStorage.getItem('list_type') : 'grid'
		}
	}
	,computed:{
		item_list: function(){

			return this.items.filter( (item) => {

				if(item.is_sold == 0 || (item.is_sold == 2 && item.pdt_stock > 0)){
					item.is_sale = true
				}
				return item
			})
		}
		, category_list: function(){
			let t = []

			this.category.filter( (item) => {
				if(this.$route.params.pdt_div == 'business') {
					if (item.type == this.$route.params.pdt_div) {
						t.push(item)
					}
				}else if(this.$route.params.pdt_div == 'ticket'){
					if(item.type == this.$route.params.pdt_div){
						t.push(item)
					}
				}else if(this.$route.params.pdt_div == 'mobile') {
					if (item.type == this.$route.params.pdt_div) {
						t.push(item)
					}
				}else if(this.$route.params.pdt_div == 'giftcon'){
					if(item.type == this.$route.params.pdt_div){
						t.push(item)
					}
				}else{
					if(item.type != 'business' && item.type != 'ticket'){
						t.push(item)
					}
				}
			})
			return t
		}
	}
	,methods: {
		getData: async function() {

			this.$bus.$emit('on', true)

			try {
				const result = await this.$request.init({
					method: 'get'
					, url: 'product/getProductList'
					, data: this.search
				})

				if (result.success) {
					let item = this.items
					if(this.search.type == 'more'){
						this.items = item.concat(result.data.result)
					}else{
						this.items = result.data.result
					}
					this.$set(this.search, 'total_count', result.data.tCnt)
					this.$storage.setQuery(this.search)
				} else {
					this.$bus.$emit('notify', {type: 'error', message: result.message})
				}
			} catch (E) {
				console.log(E)
				this.$bus.$emit('notify', {type: 'error', message: E})
			} finally {
				this.$bus.$emit('on', false)
			}
		}
		,toDetail(item){
			this.$bus.$emit('to', { name: 'ProductDetail', params: { idx: item.uid }, not_query: true})
		}
		,clear(){
			this.$set(this, 'item', {})
			//this.setProgram(this.program)
		}
		,setNotify({ type, message}){
			this.$bus.$emit('notify', { type: type, message: message })
		}
		,setProgram(program){
			this.$emit('onLoad', program)
		}
		,toCart: function(){
			this.$bus.$emit('to', 'Cart')
		}
		,toCategory: function(category){
			this.category_now = category
			this.search.pdt_category = category.category_code
			this.getSearch()
		}
		,setListType: function(list_type){
			this.list_type = list_type
			localStorage.setItem('list_type', list_type)
		}
		,getSearch: async function(){
			this.items = []
			this.search.page = 1
			await this.getData()
		}
		, auth: function(){
			if(!this.$route.params.pdt_div || this.user.member_level > 0){
				this.getData()
			}else{
				this.$bus.$emit('notify', { type: 'error', message: '회원 전용입니다.'})
				this.$router.back()
			}
		}
	}
	,created: function(){
		this.$bus.$emit('onLoad', this.program)
		this.auth()
	}
	,watch: {
		$router(){
			this.auth()
		}
	}
}
</script>

<style>

	@media (max-width: 720px) {
		.list-box-pdt {
			width: calc(50% - 10px);
		}
	}
	@media (min-width: 720px) {
		.list-box-pdt {
			width: calc(25% - 60px / 4);
		}
	}
</style>